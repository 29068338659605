import { Switch } from '@headlessui/react'
import { classNames } from '../../Helpers'

export default function Toggle({ label, labelClass = '', description, state, action, disabled, tiny = false }) {
    return (
        <Switch.Group as="div" className="flex items-center justify-between">
            <Switch
                checked={state}
                onChange={disabled ? () => { } : action} // Prevents the switch action when disabled
                className={classNames(
                    state && !disabled ? 'bg-blue-600' : 'bg-gray-200',
                    !disabled ? "cursor-pointer" : "cursor-not-allowed",
                    tiny ? 'h-4 w-8' : 'h-6 w-11',
                    'relative inline-flex shrink-0 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
                disabled={disabled} // This line ensures the switch respects the disabled prop
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        !state && 'translate-x-0',
                        (state && !tiny) && 'translate-x-5',
                        (state && tiny) && 'translate-x-4',
                        tiny ? 'h-3 w-3' : 'h-5 w-5',
                        'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            <span className={classNames("flex-grow flex flex-col ml-3 text-sm", !disabled ? "text-gray-900" : "text-gray-400")}>
                <Switch.Label as="span" className={classNames("font-medium", labelClass)} passive>
                    {label}
                </Switch.Label>
                {description &&
                    <Switch.Description as="span" className={classNames(!disabled ? "text-gray-500" : "text-gray-400")}>
                        {description} {state}
                    </Switch.Description>
                }
            </span>
        </Switch.Group>
    )
}
