import { useFormContext } from "react-hook-form"
import {blank, classNames, lang, t} from '@/components/common/Helpers'
import LaravelErrorOutput from "./LaravelErrorOutput"

export default function TextArea({ label, name, required, placeholder, defaultValue, rows = "5", className, disabled = false, submitErrors = null, maxLength, minLength }) {

    const { register, formState: { errors }, setValue, getValues, watch } = useFormContext()

    if (maxLength) {
        watch(name)
    }

    const inputDefaultClasses = 'block w-full bg-gray-50 border-0 border-transparent border-b sm:text-md'
    const inputCleanClasses = 'focus:border-blue-600 focus:ring-0'
    const inputErrorClasses = 'pr-10 border-red border-red-500 text-red-900 placeholder-red-300 focus:outline-none focus:ring-0 focus:border-red-500'

    const wrapperDefaultClasses = 'mt-1 border-b'
    const wrapperCleanClasses = 'border-gray-300 focus-within:border-blue-600'
    const wrapperErrorClasses = 'border-red-500 focus-within:border-red-500'

    const wrapperClassName = wrapperDefaultClasses + ' ' + (errors && errors[name] ? wrapperErrorClasses : wrapperCleanClasses)
    const inputClassName = inputDefaultClasses + ' ' + (errors && errors[name] ? inputErrorClasses : inputCleanClasses)

    const locale = lang

    return (
        <>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className={wrapperClassName}>
                <textarea
                    {...register(name, { required })}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    className={classNames(inputClassName, className)}
                    rows={rows}
                    disabled={disabled}
                    maxLength={maxLength}
                    minLength={minLength}
                />
            </div>
            {maxLength ? (
                <div className="flex justify-between text-xs text-gray-400">
                    <div>Max characters: {maxLength}</div>
                    <div>{getValues(name)?.length || 0} / {maxLength}</div>
                </div>
            ) : ''}
            {
                errors && errors[name]?.type === 'required' && (
                    <div>
                        <p className="mt-2 text-sm text-red-600">
                            {blank(label) || (typeof locale === 'string' && locale.slice(0,2) !== 'en') ? (
                                <span>{t.trans('forms.required')}</span>
                            ) : (
                                <span>{label} is required</span>
                            )}
                        </p>
                    </div>
                )
            }
            {
                <LaravelErrorOutput submitErrors={submitErrors} name={name} />
            }
        </>
    )
}
