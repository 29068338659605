// ONLY MODIFY THIS FILE BY RUNNING THE FOLLOWING COMMAND:
// php artisan react:enum

export const CurrencyType = {
    USD: {
        Name: 'U S D',
        Value: 'USD',
        Symbol: '$',
    },
    CAD: {
        Name: 'C A D',
        Value: 'CAD',
        Symbol: 'CA$',
    },
}